import {
  ThemeProvider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import theme from "../../../theme";
import useStyles from "./SingleItemStyles";
import { globalStyles } from "../../../theme";
import ShelfIcon from "../../../Assets/Icons/Shelf.png";
import MoneyIcon from "../../../Assets/Icons/Money.png";
import StarIcon from "../../../Assets/Icons/Star.png";
import { PieChart } from "@mui/x-charts/PieChart";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import RedBullCan from "../../../Assets/Images/redbull.png";
import ArrowDown from "../../../Assets/Icons/ArrowDown.png";
import GoBackHeader from "../../../Components/GoBackHeader/GoBackHeader";
import ContainerTitle from "../../../Components/ContainerTitle/ContainerTitle";

const data = [
  { label: "Red Bull", facing: 6, value: "14.33", color: "#194FF0" },
  { label: "Power Root", facing: 8, value: "29.46", color: "#08E0DB" },
  { label: "Warrior", facing: 5, value: "23.47", color: "#02207D" },
  { label: "Hivetown", facing: 2, value: "20.30", color: "#5D5FEF" },
  { label: "Pasan", facing: 2, value: "12.50", color: "#B872FF" },
];

const tabData = [
  {
    category: "150ml",
    content: [
      {
        product: "Competitor A",
        price: "14.52",
        change_direction: "+",
        change_percentage: 68,
      },
      {
        product: "Competitor B",
        price: "16.80",
        change_direction: "+",
        change_percentage: 60,
      },
      {
        product: "Red Bull",
        price: "9.00",
        change_direction: "",
        change_percentage: 0,
        main_product: true,
      },
      {
        product: "Competitor F",
        price: "17.82",
        change_direction: "-",
        change_percentage: 20,
      },
      {
        product: "Competitor D",
        price: "7.13",
        change_direction: "-",
        change_percentage: 12,
      },
    ],
  },
  {
    category: "250ml",
    content: [
      {
        product: "Competitor A",
        price: "14.52",
        change_direction: "+",
        change_percentage: 68,
      },
      {
        product: "Competitor B",
        price: "16.80",
        change_direction: "+",
        change_percentage: 60,
      },
      {
        product: "Red Bull",
        price: "9.00",
        change_direction: "",
        change_percentage: 0,
        main_product: true,
      },
      {
        product: "Competitor F",
        price: "17.82",
        change_direction: "-",
        change_percentage: 20,
      },
      {
        product: "Competitor D",
        price: "7.13",
        change_direction: "-",
        change_percentage: 12,
      },
    ],
  },
];

const skuData = [
  {
    competitor: {
      product: RedBullCan,
      text: "Power Root Extra HoneyTongkat Ali and Ginseng 250ml",
    },
    oppurtunity: { product: RedBullCan, text: "Red Bull Plus 250ml" },
  },
  {
    competitor: {
      product: RedBullCan,
      text: "Hivetown Acacia Honey Sparkling 320ml",
    },
    oppurtunity: { product: RedBullCan, text: "Red Bull Apple Muscat 250ml" },
  },
  {
    competitor: { product: RedBullCan, text: "Warrior Strawberry 320ml" },
    oppurtunity: { product: RedBullCan, text: "Red Bull Gold 325ml" },
  },
];

const TabPanel = ({ children, value, index, theme2 }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      dir={theme2.direction}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const SingleItem = () => {
  const globalstyles = globalStyles();
  const classes = useStyles();
  const theme2 = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSwipeChange = (index) => {
    setValue(index);
  };

  return (
    <ThemeProvider theme={theme}>
      <GoBackHeader text="Energy Drink" location={"/analysis"}/>
      <div className={globalstyles.mainContainer}>
        <div className={globalstyles.innerContainer}>
        <ContainerTitle title="Share of shelf" icon={ShelfIcon} />
          <div className={classes.chartContainer}>
            <PieChart
              series={[
                {
                  data: data,
                  innerRadius: 79,
                  outerRadius: 100,
                  paddingAngle: 1,
                  cornerRadius: 4,
                  startAngle: 0,
                  endAngle: 360,
                  cx: 90, // Center horizontally
                },
              ]}
              height={200}
              width={200}
              slotProps={{
                legend: { hidden: true },
              }}
              colors={data.map((item) => item.color)}
            />
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center", borderBottom: "none" }}
                  ></TableCell>
                  <TableCell
                    style={{ textAlign: "center", borderBottom: "none" }}
                  >
                    <Typography
                      variant="bodySmall"
                      style={{
                        color: theme.palette.neutral[400],
                        textAlign: "center",
                      }}
                    >
                      Facing
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "center", borderBottom: "none" }}
                  >
                    <Typography
                      variant="bodySmall"
                      style={{ color: theme.palette.neutral[400] }}
                    >
                      Share
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ textAlign: "center", borderBottom: "none" }}
                      className={classes.tableCell}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: 16,
                            height: 16,
                            backgroundColor: row.color,
                            borderRadius: "25%",
                            marginRight: 8,
                          }}
                        />
                        <Typography
                          variant="bodySmall"
                          style={{ color: theme.palette.primary[400] }}
                        >
                          {row.label}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center", borderBottom: "none" }}
                      className={classes.tableCell}
                    >
                      <Typography variant="titleSmall">{row.facing}</Typography>
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center", borderBottom: "none" }}
                      className={classes.tableCell}
                    >
                      <Typography variant="titleSmall">{row.value}%</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={globalstyles.innerContainer}>
        <ContainerTitle title="Pricing Comparison" icon={MoneyIcon} />
          <Box sx={{ width: "100%", margin: "1rem 0" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant={tabData.length > 2 ? "scrollable" : "fullWidth"}
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {tabData.map((tab, index) => (
                <Tab
                  key={index}
                  sx={{
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: `${theme.palette.primary[400]} !important`, // Selected tab color
                    },
                  }}
                  label={
                    <Typography variant="titleSmall" component="span">
                      {tab.category}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
            <SwipeableViews
              axis={theme2.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleSwipeChange}
            >
              {tabData.map((tab, contentIndex) => (
                <TabPanel
                  key={contentIndex}
                  value={value}
                  index={contentIndex}
                  theme2={theme2}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              Product
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              Price
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tab.content &&
                          tab.content.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="bodySmall"
                                    style={{
                                      color: row.main_product
                                        ? theme.palette.primary[400]
                                        : theme.palette.neutral[700],
                                    }}
                                  >
                                    {row.product}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                <Typography
                                  variant="titleSmall"
                                  style={{
                                    color: row.main_product
                                      ? theme.palette.primary[400]
                                      : row.change_direction === "+"
                                      ? theme.palette.success[300]
                                      : theme.palette.error[300],
                                  }}
                                >
                                  ${row.price}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {row.change_percentage === 0 ? (
                                  ""
                                ) : (
                                  <Typography
                                    variant="caption"
                                    className={
                                      row.change_direction === "+"
                                        ? classes.tabStatus2
                                        : classes.tabStatus0
                                    }
                                  >
                                    {row.change_direction}
                                    {row.change_percentage}%
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              ))}
            </SwipeableViews>
          </Box>
        </div>
        <div className={globalstyles.innerContainer}>
        <ContainerTitle title="SKU distribution opportunity" icon={StarIcon}/>
          <div>
            {skuData.map((table, index) => (
              <TableContainer
                key={index}
                className={globalstyles.greyContainer}
              >
                <Table style={{ width: "auto" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <img
                          alt="red bull can"
                          src={RedBullCan}
                          className={classes.sku_image}
                        />
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="caption"
                            style={{ color: theme.palette.neutral[400] }}
                          >
                            Competitor found
                          </Typography>
                          <Typography variant="titleSmall">
                            {table.competitor.text}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          textAlign: "center",
                          padding: 0,
                        }}
                      >
                        <img
                          src={ArrowDown}
                          alt="arrow down"
                          className={classes.checkIcon}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <img
                          alt="red bull can"
                          src={RedBullCan}
                          className={classes.sku_image}
                        />
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="caption"
                            style={{ color: theme.palette.neutral[400] }}
                          >
                            Opputunity
                          </Typography>
                          <Typography
                            variant="titleSmall"
                            style={{ color: "#0035D4" }}
                          >
                            {table.oppurtunity.text}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SingleItem;
