import React, { useEffect, useState, useRef } from "react";
import theme from "../../../theme";
import { ThemeProvider } from "@mui/styles";
import { Typography, Box, Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import useStyles from "./chillerModalStyle";
import { useStoreState, useStoreActions } from "easy-peasy";
import Locale from "../../../locale";
import Card from "../../Card/card";
import CloseIcon from "../../../Assets/Icons/Close.png";
import { useNavigate } from "react-router-dom";

import RestApi from "../../../Utils/Backend";

const ChillerModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const locale = Locale();
  const classes = useStyles();
  const images = useStoreState((state) => state.images);
  const sessionId = useStoreState((state) => state.sessionId);
  const setPlanogramData = useStoreActions(
    (actions) => actions.setPlanogramData
  );
  const setPosmData = useStoreActions((actions) => actions.setPosmData);

  const hasMountedPlanogram = useRef(false);
  const hasMountedPOSM = useRef(false);

  useEffect(() => {
    const getPlanogram = async () => {
      const planogramData = await RestApi().uploadImage(
        "/backend/get_planogram/",
        sessionId,
        "chiller",
        images[0]
      );
      setPlanogramData(planogramData);
    };

    // Only run if this is not the initial render
    if (hasMountedPlanogram.current && images[0]) {
      getPlanogram();
    } else {
      hasMountedPlanogram.current = true;
    }
  }, [images[0]]);

  useEffect(() => {
    const getPOSM = async () => {
      const posmData = await RestApi().uploadImage(
        "/backend/get_posm/",
        sessionId,
        "chiller",
        images[1]
      );
      setPosmData(posmData);
    };

    // Only run if this is not the initial render
    if (hasMountedPOSM.current && images[1]) {
      getPOSM();
    } else {
      hasMountedPOSM.current = true;
    }
  }, [images[1]]);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box className={classes.modalContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: "1.25rem",
              }}
            >
              <Typography id="transition-modal-title" variant="h3">
                Chiller
              </Typography>
              <img
                alt="close"
                src={CloseIcon}
                width="12px"
                height="12px"
                style={{ padding: "0.5rem" }}
                onClick={onClose}
              />
            </div>
            <Typography
              id="transition-modal-description"
              variant="bodySmall"
              className={classes.modalText}
            >
              {locale.ChillerModalText}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box
                sx={{ width: "100%", margin: "1rem 0 0 0", textAlign: "left" }}
              >
                <Card
                  number="1"
                  title={locale.ChillerOpenText}
                  capture
                  childTitle="Chiller"
                  picIndex={0}
                />
                <Card
                  number="2"
                  title={locale.ChillerCloseText}
                  capture
                  childTitle="Chiller"
                  picIndex={1}
                />
              </Box>
              {images.length >= 2 &&
                images.every((image) => image !== null) && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate("/analysis");
                    }}
                    className={classes.modalButton}
                  >
                    {locale.AnalyzeButton}
                  </Button>
                )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default ChillerModal;
