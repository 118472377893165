import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "./MainPageTableStyles"; // Ensure the correct path to your useStyles
import Verified from "../../Assets/Icons/Verified.png";
import Unverified from "../../Assets/Icons/Unverified.png";
import Locale from "../../locale";

const MainPageTable = () => {
  const locale = Locale();
  const classes = useStyles();
  const tableData = [
    //Get by calling the API
    {
      headers: [" ", "Red Bull", "Spritzer", "Cactus", "Warrior"],
    },
    {
      data: [
        {
          name: "Chiller",
          status_1: 1,
          status_2: null,
          status_3: null,
          status_4: null,
          status_5: null,
          status_6: null,
          status_7: null,
          status_8: null,
        },
        {
          name: "Home Shelve",
          status_1: null,
          status_2: null,
          status_3: null,
          status_4: null,
          status_5: null,
          status_6: null,
          status_7: null,
          status_8: null,
        },
        {
          name: "Display / Block / Gondola End",
          status_1: null,
          status_2: null,
          status_3: null,
          status_4: null,
          status_5: null,
          status_6: null,
          status_7: null,
          status_8: null,
        },
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.mainContainer}>
        <div className={classes.statusContainer}>
          <div className={classes.statusItem}>
            <div className={classes.verifiedCircle}></div>
            <Typography variant="caption">{locale.VerifiedText}</Typography>
          </div>
          <div className={classes.statusItem}>
            <div className={classes.unverifiedCircle}></div>
            <Typography variant="caption">{locale.UnverfiedText}</Typography>
          </div>
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-label="simple table"
            size="medium" // Use size prop to adjust the padding
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                {tableData[0].headers.map((header, index) => (
                  <TableCell
                    key={index}
                    className={
                      index === 0
                        ? classes.tableCellName
                        : classes.tableHeadCell
                    }
                    align={index === 0 ? "left" : "right"}
                  >
                    <Typography variant="caption">{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData[1].data.map((row, rowIndex) => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.tableCellName}
                    component="th"
                    scope="row"
                  >
                    <Typography variant="caption">{row.name}</Typography>
                  </TableCell>

                  {tableData[0].headers.slice(1).map((header, cellIndex) => {
                    const statusKey = `status_${cellIndex + 1}`; // Create the key dynamically
                    return (
                      <TableCell
                        className={classes.tableCellStatus}
                        align="right"
                        key={cellIndex}
                      >
                        {row[statusKey] != null ? (
                          <img
                            src={row[statusKey] === 1 ? Verified : Unverified}
                            alt="status"
                            style={{ width: "1.6rem", height: "1.6rem" }}
                          />
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
};

export default MainPageTable;
