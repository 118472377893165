import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        height: "100%"
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between', // Space between the cancel button and title
        alignItems: 'center', // Center items vertically
        padding: '1rem',
        position: 'relative', // Relative position for absolute child if needed
    },
    cancelButton: {
        color: theme.palette.primary[400],
        cursor: 'pointer', // Change cursor to pointer on hover
        zIndex: 1, // Ensure cancel button is on top of other elements
    },
    title: {
        flex: 1, // Allow title to take remaining space
        textAlign: 'center', // Center text horizontally
        left:0,
        right:0,
        position:'absolute'
    },
    descriptionContainer:{
        display: 'flex',
        flexDirection: 'row',
        margin: "0.5rem 1rem 1rem",
        color: theme.palette.neutral[400],
    },
    imageCount:{
        marginRight: "0.75rem !important"
    },
    captureButton:{
        width: "3.75rem",
        height: "3.75rem",
        margin: "1.75rem 0",
        flex: 1, // Allow title to take remaining space
        textAlign: 'center', // Center text horizontally
        position: 'absolute', // Position title absolutely for centering

    },
    buttonContainer:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        margin: "1rem 0",
    },
    postCameraButtons:{
        display: 'flex',
        flexDirection: 'row !important',
        minWidth: "10.313rem !important",
        textTransform: "none !important",
        margin: "1rem 0.375rem 0 0.375rem !important",
        padding: "0.5rem 1.516rem !important"
    },
    leaveModalButton1: {
        margin: "0 0.313rem !important",
        textTransform: "none !important",
        padding: "0.5rem 1.516rem !important",
        color:`${theme.palette.primary[400]} !important`,
        borderColor: `${theme.palette.primary[300]} !important`,
        borderRadius: '0.5rem !important'

    },
    leaveModalButton2: {
        margin: "0 0.313rem !important",
        textTransform: "none !important",
        padding: "0.5rem 1.516rem !important",
        backgroundColor: `${theme.palette.primary[400]} !important`,
        borderRadius: '0.5rem !important'

    },
    cameraOptions: {
        margin: "2.5rem 1rem",
        display: 'flex',
        justifyContent: 'center', // Space between the cancel button and title
        alignItems: 'center', // Center items vertically
        padding: '1rem',
        position: 'relative', // Relative position for absolute child if needed
    },
    savedImages: {
        cursor: 'pointer', // Change cursor to pointer on hover
        zIndex: 1, // Ensure cancel button is on top of other elements
        left:0,
        position: 'absolute',
        width: "3.75rem",
        height: "3.75rem",
        objectFit: "cover",
        objectPosition: "center",
        borderRadius: "20%",
    },
    buttonIcon: {
        filter: "brightness(0) invert(1)",
        width: "1.25rem",
        height: "1.25rem",
        margin: "0.5rem",
      },
}));

export default useStyles;
