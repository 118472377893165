import { createStore, action } from "easy-peasy";

const MainStore = createStore({
  //Locale
  locale: "EN",
  setLocale: action((state, payload) => {
    state.locale = payload;
  }),

  /// ImageCaptures
  images: [],
  addImage: action((state, payload) => {
    if (payload.index >= state.images.length) {
      // Expand the array if needed
      state.images.length = payload.index + 1;
    }
    state.images[payload.index] = payload.value;
  }),

  // Remove image at a specific index
  removeImage: action((state, index) => {
    state.images[index] = null;
    // state.images.splice(index, 1); // Removes the item at the specified index
  }),

  // Optional: If you want to add multiple images at once, you can use a separate action for that:
  addImages: action((state, payload) => {
    state.images = [...state.images, ...payload]; // Adds multiple images
  }),

  sessionId: "",
  setSessionId: action((state, payload) => {
    state.sessionId = payload;
  }),

  planogramData: {},
  setPlanogramData: action((state, payload) => {
    state.planogramData = payload;
  }),

  posmData: {},
  setPosmData: action((state, payload) => {
    state.posmData = payload;
  }),
});

export default MainStore;
