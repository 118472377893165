import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme, {globalStyles} from "../../theme";
import { Typography } from "@mui/material";

const Footer = () => {
  const classes = globalStyles();
  return (
    <ThemeProvider theme={theme}>
      <div style={{
        textAlign: "center",
        padding: "1rem 0",
      }}>
        <Typography
          variant="caption"
          className={classes.footerText}
        >
          ⓒ 2024 Planogram.AI All Right Reserved
        </Typography>
      </div>
    </ThemeProvider>
  );
};

export default Footer;
