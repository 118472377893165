import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    checkIcon:{
        width: "1.25rem",
        height: "1.25rem",
      },
      checkIconContainer:{
        margin: "0 0.5rem 0 0",
        padding: "0.375rem",
        backgroundColor: theme.palette.primary[100],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "25%",
      },
      subtitle:{
        color: theme.palette.primary[500],
      },
}));

export default useStyles;