import { ThemeProvider } from "@mui/styles";
import React, { useState, useRef } from "react";
import theme from "../../theme";
import { Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./CameraInputStyles";
import Frame from "../../Assets/Icons/range.png";
import CaptureButton from "../../Assets/Icons/Capture_button.png";
// import { Camera } from "react-camera-pro";
import CameraIcon from "../../Assets/Icons/Camera.png";
import Locale from "../../locale";
import Webcam from "react-webcam";

const CameraInput = () => {
  const locale = Locale();
  const navigate = useNavigate();
//   const camera = useRef(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [prevImage, setPrevImage] = useState(null);
  const [imgCount, setImgCount] = useState(1);
  const classes = useStyles();
  const location = useLocation();
  const { title } = location.state || {};
  const videoConstraints = {
    width: 3024,
    height: 4032,
    facingMode: "environment",
  };

  const webcamRef = useRef(null);
  const webcamRef2 = useRef(null);

  // const saveBase64AsFile = (base64, fileName) => {
  //   // Convert base64 string to a binary large object (Blob)
  //   const byteString = atob(base64.split(",")[1]); // decode base64
  //   const mimeString = base64.split(",")[0].split(":")[1].split(";")[0]; // get MIME type
  //   const byteNumbers = new Array(byteString.length);

  //   for (let i = 0; i < byteString.length; i++) {
  //     byteNumbers[i] = byteString.charCodeAt(i);
  //   }

  //   const byteArray = new Uint8Array(byteNumbers);
  //   const blob = new Blob([byteArray], { type: mimeString });

  //   // Create a link element
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob); // create an object URL from Blob
  //   link.download = fileName;

  //   // Programmatically click the link to trigger download
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <ThemeProvider theme={theme}>
      <Webcam
        style={{ display: "none" }}
        width={3024}
        height={4032}
        audio={false}
        ref={webcamRef2}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <div className={classes.container}>
        <div className={classes.header}>
          <div
            className={classes.cancelButton}
            onClick={() => {
              navigate(-1); // Navigate to the /
            }}
          >
            <Typography variant="titleSmall">{locale.ImageCancel}</Typography>
          </div>
          <div className={classes.title}>
            <Typography variant="h3">{title}</Typography>
          </div>
        </div>
        <div className={classes.descriptionContainer}>
          <Typography variant="bodySmall" className={classes.imageCount}>
            {imgCount}/3
          </Typography>
          <Typography variant="bodySmall">
            {imgCount === 1 ? locale.ImageUploadStep1Text : null}
            {imgCount === 2 ? locale.ImageUploadStep2Text : null}
            {imgCount === 3 ? locale.ImageUploadStep3Text : null}
          </Typography>
        </div>
        {(!image2 && imgCount === 2) || (!image3 && imgCount === 3) ? (
          <>
            <div style={{ width: "100%", position: "relative" }}>
              <img
                src={Frame}
                alt="Frame"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "90%", // Adjust width to make it smaller (e.g., 90% of container width)
                  height: "90%", // Adjust height accordingly
                  transform: "translate(-50%, -50%)", // Center the frame within the container
                  zIndex: 1000,
                  pointerEvents: "none",
                }}
              />
              <Webcam
                width={430}
                height={573}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: "environment" }}
              />
            </div>
            <div className={classes.cameraOptions}>
              <img
                alt="prev images"
                src={prevImage}
                className={classes.savedImages}
              />
              <img
                src={CaptureButton}
                alt="capture"
                className={classes.captureButton}
                onClick={() => {
                    console.log("capture");
                    console.log(imgCount);
                    console.log(image2);
                    
                  if (imgCount === 2) {
                    setImage2(
                      webcamRef2.current.getScreenshot({
                        width: 3024,
                        height: 4032,
                      })
                    );
                  } else if (imgCount === 3) {
                    setImage3(
                      webcamRef2.current.getScreenshot({
                        width: 3024,
                        height: 4032,
                      })
                    );
                  }
                  setPrevImage(
                    webcamRef2.current.getScreenshot({ width: 430, height: 573 })
                  );
                }}
              />
            </div>
          </>
        ) : !image1 && imgCount === 1 ? (
          <center>
            <div style={{ width: "100%", position: "relative" }}>
              <img
                src={Frame}
                alt="Frame"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "90%", // Adjust width to make it smaller (e.g., 90% of container width)
                  height: "90%", // Adjust height accordingly
                  transform: "translate(-50%, -50%)", // Center the frame within the container
                  zIndex: 1000,
                  pointerEvents: "none",
                }}
              />
              <Webcam
                forceScreenshotSourceSize={true}
                width={430}
                height={573}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: "environment" }}
              />
            </div>
            <img
              src={CaptureButton}
              alt="capture"
              style={{
                width: "3.75rem",
                height: "3.75rem",
                margin: "1.75rem 0",
              }}
              onClick={() => {
                if (imgCount === 1) {
                  setImage1(
                    webcamRef2.current.getScreenshot({
                      width: 3024,
                      height: 4032,
                    })
                  );
                  setPrevImage(
                    webcamRef2.current.getScreenshot({ width: 430, height: 573 })
                  );
                //   saveBase64AsFile(
                //     webcamRef2.current.getScreenshot({
                //       width: 3024,
                //       height: 4032,
                //     }),
                //     "captured_image.png"
                //   );
                }
              }}
            />
          </center>
        ) : null}

        {(image1 && imgCount === 1) ||
        (image2 && imgCount === 2) ||
        (image3 && imgCount === 3) ? (
          <>
            <img src={prevImage} alt="Photo1" />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.postCameraButtons}
                variant="outlined"
                onClick={() => {
                  if (imgCount === 1) {
                    setImage1(null);
                    setImgCount(1);
                  } else if (imgCount === 2) {
                    setImage2(null);
                    setImgCount(2);
                  } else if (imgCount === 3) {
                    setImage3(null);
                    setImgCount(3);
                  }
                }}
              >
                <img
                  alt="Recapture"
                  src={CameraIcon}
                  style={{ marginRight: "0.75rem", width: "1.25rem" }}
                />
                <Typography variant="titleSmall">{locale.Recapture}</Typography>
              </Button>
              <Button
                className={classes.postCameraButtons}
                variant="contained"
                onClick={() => {
                  if (imgCount === 1) {
                    setImgCount(2);
                    setPrevImage(image1);
                  } else if (imgCount === 2) {
                    setImgCount(3);
                    setPrevImage(image2);
                  } else if (imgCount === 3) {
                    setImgCount(4);
                    setPrevImage(image3);
                  }
                }}
              >
                <Typography variant="titleSmall">{imgCount < 3 ? locale.SaveAndContinue : locale.Done}</Typography>
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </ThemeProvider>
  );
};

export default CameraInput;
