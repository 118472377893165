import React from "react";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme";
import { Typography } from "@mui/material";
import infoIcon from "./../../Assets/Icons/Info.png";
import Locale from "../../locale";

const Banner = () => {
  const locale = Locale();

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          padding: "1rem",
          backgroundColor: theme.palette.primary[50],
          color: theme.palette.primary[500],
        }}
      >
        <div>
          <img
            src={infoIcon}
            alt="Info Icon"
            style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }}
          />
        </div>
        <div>
          <Typography variant="bodySmall">{locale.HeaderTopBanner}</Typography>
        </div>
      </div>
    </ThemeProvider>
  );
};
export default Banner;
