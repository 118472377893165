import { ThemeProvider } from "@emotion/react";
import React, {useState} from "react";
import theme from "../../theme";
// import { Typography, Divider, Button, MenuItem, Select, InputLabel, FormControl, Box, Checkbox } from "@mui/material";
import { Typography, Button} from "@mui/material";
// import End_Icon from "../../Assets/Icons/End Icon.png";
import Camera from "../../Assets/Icons/Camera.png";
import Locale from "../../locale";
import useStyles from "./cardStyle";
import CameraModal from "../Modals/Camera/cameraModal";
import { useStoreState } from "easy-peasy";

const Card = (props) => {
  const images = useStoreState((state) => state.images);
  const classes = useStyles();
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);

  const openCameraModal = () => setIsCameraModalOpen(true);
  const closeCameraModal = () => setIsCameraModalOpen(false);

  // const [age, setAge] = useState("");
  // const [posmMaterials, setPosmMaterials] = useState([]);
  // const [checkedItems, setCheckedItems] = useState({
  //   "priceTag-3": false,
  //   "priceTag-2": false,
  // });

  // const handleChange = (event) => {
  //   const { name, value, checked } = event.target;

  //   if (name?.startsWith("priceTag")) {
  //     setCheckedItems((prev) => ({
  //       ...prev,
  //       [name]: checked,
  //     }));
  //   } else {
  //     setAge(value);
  //   }
  // };

  // const handlePOSMMaterials = (event) => {
  //   const { value } = event.target;
  //   setPosmMaterials(
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  const locale = Locale();

  return (
    <ThemeProvider theme={theme}>
      <CameraModal open={isCameraModalOpen} onClose={closeCameraModal} modalTitle={props.childTitle} picIndex={props.picIndex}/>
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.circle} />
            <Typography sx={{ color: theme.palette.primary[400] }} variant="caption">
              {props.number}
            </Typography>
          </div>
          <Typography variant="titleLarge" sx={{ color: theme.palette.primary[500], textAlign: "left" }}>
            {props.title}
          </Typography>
        </div>

        {props.capture && images[props.picIndex] == null ? (
         <Button className={classes.button} onClick={()=> {openCameraModal()}
         }>
         <img src={Camera} alt="Camera Icon" className={classes.buttonIcon} />
         <Typography variant="titleSmall">
           {locale.CaptureText}
         </Typography>
       </Button>
        ):(
          <Button
                    className={classes.postCameraButtons}
                    variant="outlined"
                    onClick={() => {
                      openCameraModal();
                    }}
                  >
                    <img
                      alt="Recapture"
                      src={Camera}
                      className={classes.recaptureIcon}
                    />
                    <Typography variant="titleSmall">
                      {locale.Recapture}
                    </Typography>
                  </Button>
        )}

        {/* {props.description && (
          <Typography className={classes.description} variant="bodySmall">
            {props.description}
          </Typography>
        )}

        {props.help && (
          <div className={classes.helpContainer}>
            <Divider sx={{ margin: "20px 0" }} />
            <div className={classes.helpRow}>
              <Typography className={classes.helpText} variant="bodySmall">
                {locale.HelpText}
              </Typography>
              <div className={classes.row}>
                <Typography className={classes.helpButtonText} variant="bodySmall">
                  {locale.HelpButtonText}
                </Typography>
                <img src={End_Icon} alt="End Icon" style={{ width: "1.4rem" }} />
              </div>
            </div>
          </div>
        )}

        {props.selectTitle && props.number === 1 && (
          <Box className={classes.selectContainer}>
            <FormControl fullWidth>
              <InputLabel id={`select-label-${props.number}`}>
                <Typography variant="bodyMedium">{props.selectTitle}</Typography>
              </InputLabel>
              <Select
                id={`select-${props.number}`}
                value={age}
                label={props.selectTitle}
                onChange={handleChange}
                sx={{ borderRadius: "0.75rem" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {props.selectOptions &&
                  props.selectOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      <Typography variant="bodyMedium">{option.label}</Typography>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {props.selectTitle && props.number === 3 && (
          <Box className={classes.selectContainer}>
            <FormControl fullWidth>
              <InputLabel id={`select-label-${props.number}`}>
                <Typography variant="bodyMedium">{props.selectTitle}</Typography>
              </InputLabel>
              <Select
                id={`select-${props.number}`}
                value={posmMaterials}
                label={props.selectTitle}
                onChange={handlePOSMMaterials}
                sx={{ borderRadius: "0.75rem" }}
                multiple
                renderValue={(selected) => selected.join(", ")}
              >
                {props.selectOptions?.length ? (
                  props.selectOptions.map((option) => (
                    <MenuItem key={option.value} value={option.label}>
                      <Checkbox checked={posmMaterials.includes(option.label)} />
                      <Typography variant="bodyMedium">{option.label}</Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>
                    <em>No options available</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        )}

        {props.number === 2 && props.capture && (
          <Button className={classes.button} style={{ width: "50%" }}>
          <img src={Camera} alt="Camera Icon" className={classes.buttonIcon} />
          <Typography variant="titleSmall" sx={{ color: theme.palette.primary[400] }}>
            {locale.CaptureText}
          </Typography>
        </Button>
        )}

        {props.number === 3 && props.capture && (
          <div className={classes.column} style={{ width: "100%", margin: "0.5rem 0 0 0" }}>
            {[1, 2, 3].map((i) => (
              <div key={i} className={classes.row} style={{ justifyContent: "space-between", margin: "1.5rem 0 0 0" }}>
                <Typography variant="bodyMedium">
                  {locale.ImageText} {i}
                </Typography>
                <Button className={classes.button} style={{ width: "50%" }}>
                  <img src={Camera} alt="Camera Icon" className={classes.buttonIcon} />
                  <Typography variant="titleSmall" sx={{ color: theme.palette.primary[400] }}>
                    {locale.CaptureText}
                  </Typography>
                </Button>
              </div>
            ))}
          </div>
        )}

        {props.priceTag && (
          <div className={classes.priceTagContainer}>
            <Checkbox
              name={`priceTag-${props.number}`}
              checked={checkedItems[`priceTag-${props.number}`]}
              onChange={handleChange}
              className={classes.checkbox}
            />
            <Typography variant="bodySmall" sx={{ color: theme.palette.neutral[600] }}>
              {locale.PriceTagInclude}
            </Typography>
          </div>
        )} */}


      </div>
    </ThemeProvider>
  );
};

export default Card;
