import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    color: theme.palette.neutral[500], // Use theme's neutral color
  },

  statusContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "0.75rem",
  },

  statusItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "1rem",
  },

  verifiedCircle: {
    backgroundColor: theme.palette.success[200], // Use theme's success color
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    marginRight: "0.25rem",
  },

  unverifiedCircle: {
    backgroundColor: theme.palette.error[200], // Use theme's error color
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    marginRight: "0.25rem",
  },

  /* Table Styles */
  tableContainer: {
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.neutral[50],
  },

  table: {
    width: "100%",
    borderCollapse: "collapse",
    margin: "0 0.75rem !important",
    minWidth: "600px",
  },

  tableHeadCell: {
    fontWeight: "bold",
    padding: "0.75rem 0 !important",
    textAlign: "center !important",
  },

  tableRow: {
    transition: "background-color 0.3s ease",
    color: `${theme.palette.neutral[400]} !important`, // Use theme's neutral color
  },

  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light, // Use theme's primary light color
    },
  },

  tableCellName: {
    margin: "0.75rem 0 !important",
    textAlign: "left !important",
    color: `${theme.palette.neutral[400]} !important`, // Use theme's neutral color
    width: "5.2rem",
    position: "sticky",
    left: 0,
    background: theme.palette.neutral[50],
    zIndex: 1,
  },
  tableCellStatus: {
    padding: "0.75rem 0 !important",
    textAlign: "center !important",
    color: `${theme.palette.neutral[400]} !important`, // Use theme's neutral color
    width: "4.75rem",
  }

}));

export default useStyles;
