import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  Typography,
  Tabs,
  Tab,
  Box,
  Divider,
  Button,
} from "@mui/material";
import theme from "../../../theme";
import useStyles from "./AnalysisStyles";
import CheckIcon from "../../../Assets/Icons/cicle-check.png";
import StarIcon from "../../../Assets/Icons/Star.png";
import Lightning from "../../../Assets/Icons/Lightning.png";
import WaterIcon from "../../../Assets/Icons/water.png";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import CameraIcon from "../../../Assets/Icons/Camera.png";
import ViewCapture from "../../../Assets/Icons/view.png";
import CloseCircle from "../../../Assets/Icons/close-circle.png";
import Locale from "../../../locale";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import ContainerTitle from "../../../Components/ContainerTitle/ContainerTitle";
import { globalStyles } from "../../../theme";
import { useStoreState, useStoreActions } from "easy-peasy";

import ChillerModal from "../../../Components/Modals/Chiller/chillerModal";

const replacePlaceholders = (template, replacements) => {
  const templateString = JSON.stringify(template);
  let filledTemplateString = templateString;

  for (const [key, value] of Object.entries(replacements)) {
    const placeholder = `{${key}}`;
    filledTemplateString = filledTemplateString.replaceAll(placeholder, value);
  }

  return JSON.parse(filledTemplateString);
};

// Define template with placeholders as strings
const tabDataTemplate = [
  {
    label: "Redbull",
    content: [
      {
        name: "Chiller",
        status: "{ph_status}",
        items: [
          {
            name: "Products",
            type: "compare",
            total: 7,
            pass: "{ph_planogram_count}",
            status: "{ph_planogram_status}",
          },
          { name: "No of shelving", type: "single", value: "{ph_shelf_count}" },
          { name: "Planogram", type: "bool", value: "{ph_planogram_match}" },
          {
            name: "POSM",
            type: "compare",
            total: 6,
            pass: "{ph_posm_count}",
            status: "{ph_posm_status}",
            items: [
              {
                name: "Ambient",
                type: "compare",
                total: 3,
                pass: "{ph_posm_ambiant_count}",
              },
              {
                name: "Chiller",
                type: "compare",
                total: 3,
                pass: "{ph_posm_chiller_count}",
              },
            ],
          },
        ],
        noneCompliance: {
          status: "{ph_nc_status}",
          items: "{ph_nc_items}",
        },
      },
    ],
  },
];

const TabPanel = ({ children, value, index, theme2 }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      dir={theme2.direction}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Analysis = () => {
  const navigate = useNavigate();
  const locale = Locale();
  const globalstyles = globalStyles();
  const classes = useStyles();
  const theme2 = useTheme();
  const [value, setValue] = useState(0);

  const planogramData = useStoreState((state) => state.planogramData);
  const posmData = useStoreState((state) => state.posmData);

  const removeImage = useStoreActions((actions) => actions.removeImage);

  const [isChillerModalOpen, setIsChillerModalOpen] = useState(false);

  const openChillerModal = () => setIsChillerModalOpen(true);
  const closeChillerModal = () => setIsChillerModalOpen(false);

  const [tabData, setTabData] = useState(() =>
    replacePlaceholders(tabDataTemplate, {
      ph_status: 0,
      ph_planogram_count: 0,
      ph_planogram_status: 0,
      ph_shelf_count: 0,
      ph_planogram_match: 0,
      ph_posm_count: 0,
      ph_posm_status: 0,
      ph_posm_ambiant_count: 0,
      ph_posm_chiller_count: 0,
      ph_nc_status: 1,
      ph_nc_items: "",
    })
  );

  useEffect(() => {
    if (
      planogramData?.match &&
      Array.isArray(planogramData.class_ids) &&
      posmData?.class_ids &&
      Array.isArray(posmData.class_ids)
    ) {
      setTabData(
        replacePlaceholders(tabDataTemplate, {
          ph_status:
            { Bad: 0, Good: 1, Better: 2, Best: 3 }[planogramData.match] || 0,
          ph_planogram_count: new Set(
            planogramData.class_ids.filter((id) => id < 8)
          ).size,
          ph_planogram_status:
            { Bad: 0, Good: 1, Better: 2, Best: 3 }[planogramData.match] || 0,
          ph_shelf_count:
            { Bad: 0, Good: 1, Better: 1.5, Best: 2 }[planogramData.match] || 0,
          ph_planogram_match: planogramData.status === "True" ? 1 : 0,
          ph_posm_count: new Set(posmData.class_ids.filter((id) => id < 15))
            .size,
          ph_posm_status: posmData.status === "Good" ? 1 : 0,
          ph_posm_ambiant_count: new Set(
            posmData.class_ids.filter((id) => id >= 1 && id <= 8)
          ).size,
          ph_posm_chiller_count: new Set(
            posmData.class_ids.filter((id) => id >= 9 && id <= 14)
          ).size,
          ph_nc_status:
            planogramData.match === "Bad"
              ? 0
              : 1 && posmData.status === "Bad"
              ? 0
              : 1,
          ph_nc_items:
            (planogramData.match === "Bad" ? "SKU" : "") +
            (planogramData.match === "Bad" && posmData.status === "Bad"
              ? ", "
              : "") +
            (posmData.status === "Bad" ? "POSM" : ""),
        })
      );
    } else {
      console.warn("Required fields are missing in planogramData or posmData");
    }
  }, [planogramData, posmData]); // Added dependencies here

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSwipeChange = (index) => {
    setValue(index);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <ChillerModal open={isChillerModalOpen} onClose={closeChillerModal} /> */}
      <center>
        <Typography variant="h2" className={classes.title}>
          Analysis Result
        </Typography>
      </center>
      <div className={globalstyles.mainContainer}>
        <div className={globalstyles.innerContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div className={classes.checkIconContainer}>
              <img
                alt="check icon"
                className={classes.checkIcon}
                src={CheckIcon}
              />
            </div>
            <Typography variant="titleMedium" className={classes.subtitle}>
              Job results
            </Typography>
          </div>
          <Box sx={{ width: "100%", margin: "1rem 0" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant={tabData.length > 2 ? "scrollable" : "fullWidth"}
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {tabData.map((tab, index) => (
                <Tab
                  key={index}
                  sx={{
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: `${theme.palette.primary[400]} !important`, // Selected tab color
                    },
                  }}
                  label={
                    <Typography variant="titleSmall" component="span">
                      {tab.label}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
            <SwipeableViews
              axis={theme2.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleSwipeChange}
            >
              {tabData.map((tab, index) => (
                <TabPanel value={value} index={index} theme2={theme2}>
                  {/* Content Mapping */}
                  {tab.content.map((item, contentIndex) => (
                    <div key={contentIndex}>
                      <div className={classes.tabStatusContainer}>
                        <Typography
                          variant="titleMedium"
                          sx={{
                            color:
                              item.status == 0
                                ? theme.palette.error[300]
                                : theme.palette.neutral[700],
                          }}
                        >
                          {item.name}
                        </Typography>
                        <div
                          className={
                            item.status == 0
                              ? classes.tabStatus0
                              : classes.tabStatus2
                          }
                        >
                          <Typography variant="caption">
                            {item.status == 0
                              ? "Fail"
                              : item.status == 1
                              ? "Good"
                              : item.status == 2
                              ? "Better"
                              : "Best"}
                          </Typography>
                        </div>
                      </div>
                      {/* Content items mapping */}
                      <div className={classes.tabItemContainer}>
                        {item.items.map((item, itemIndex) => (
                          <div key={itemIndex}>
                            <div className={classes.tabItem}>
                              <Typography variant="bodySmall">
                                {item.name}
                              </Typography>
                              <div>
                                {item.type === "bool" && (
                                  <Typography variant="titleSmall">
                                    {item.value === 1 ? "Yes" : "No"}
                                  </Typography>
                                )}
                                {item.type === "single" && (
                                  <Typography variant="titleSmall">
                                    {item.value}
                                  </Typography>
                                )}
                                {item.type === "compare" && (
                                  <>
                                    <Typography
                                      variant="titleSmall"
                                      style={{
                                        color:
                                          item.status == 0
                                            ? theme.palette.error[300]
                                            : theme.palette.neutral[500],
                                      }}
                                    >
                                      {item.pass}
                                    </Typography>
                                    /
                                    <Typography variant="titleSmall">
                                      {item.total}
                                    </Typography>
                                  </>
                                )}
                              </div>
                            </div>
                            {/* Sub items of the content item mapping */}
                            {item.items && (
                              <div className={classes.tabSubItemContainer}>
                                {item.items &&
                                  item.items.map((subItem, index2) => (
                                    <div
                                      key={index2}
                                      className={classes.tabItem}
                                    >
                                      <Typography variant="bodySmall">
                                        {subItem.name}
                                      </Typography>
                                      <div>
                                        {subItem.type === "bool" && (
                                          <Typography variant="titleSmall">
                                            {subItem.value === 1 ? "Yes" : "No"}
                                          </Typography>
                                        )}
                                        {subItem.type === "single" && (
                                          <Typography variant="titleSmall">
                                            {subItem.value}
                                          </Typography>
                                        )}
                                        {subItem.type === "compare" && (
                                          <>
                                            <Typography variant="titleSmall">
                                              {subItem.pass}
                                            </Typography>
                                            /
                                            <Typography variant="titleSmall">
                                              {subItem.total}
                                            </Typography>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      {item.noneCompliance.status == 0 ? (
                        <div className={classes.noneComplianceContainer}>
                          <div className={globalstyles.redContainer}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={CloseCircle}
                                alt="Close circle icon"
                                className={classes.noneComplianceIcon}
                              />
                              <Typography
                                variant="titleSmall"
                                className={classes.noneComplianceTitle}
                              >
                                None Compliance
                              </Typography>
                            </div>
                            <Typography
                              variant="bodySmall"
                              className={classes.noneComplianceItems}
                            >
                              {item.noneCompliance.items}
                            </Typography>
                            <Button
                              onClick={() => navigate("/analysis/viewCapture")}
                              variant="outlined"
                              className={classes.recaptureButton}
                              sx={{ bgcolor: "white" }}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{ marginRight: "0.5rem" }}
                              >
                                View Capture
                              </Typography>
                              <img
                                alt="Recapture icon"
                                className={classes.checkIcon}
                                src={ViewCapture}
                              />
                            </Button>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className={classes.noneComplianceRecapButton}
                              onClick={() => {
                                openChillerModal();
                              }}
                            >
                              <Typography variant="bodySmall">
                                Recapture
                              </Typography>
                              <img
                                alt="Recapture icon"
                                className={classes.recaptureIcon}
                                src={CameraIcon}
                              />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <Button
                          onClick={() => navigate("/analysis/viewCapture")}
                          variant="outlined"
                          className={classes.recaptureButton}
                        >
                          <Typography
                            variant="bodySmall"
                            style={{
                              marginRight: "0.5rem",
                              color: theme.palette.primary[400],
                            }}
                          >
                            View Capture
                          </Typography>
                          <img
                            alt="Recapture icon"
                            className={classes.checkIcon}
                            src={ViewCapture}
                          />
                        </Button>
                      )}
                      {contentIndex < tab.content.length - 1 && (
                        <Divider
                          sx={{
                            margin: "1.25rem 0",
                            bgcolor: theme.palette.neutral[100],
                          }}
                        />
                      )}
                    </div>
                  ))}
                </TabPanel>
              ))}
            </SwipeableViews>
          </Box>
        </div>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle
            title="Competitive BI"
            icon={StarIcon}
            alt={"Competitive BI"}
          />
          <div style={{ marginTop: "1rem" }}>
            <div
              className={classes.tabItemContainer}
              onClick={() => navigate("/analysis/singleItem")}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="check icon"
                    className={classes.checkIcon}
                    src={Lightning}
                    style={{ marginRight: "0.5rem" }}
                  />

                  <Typography
                    variant="titleSmall"
                    className={classes.subtitle}
                    style={{ color: theme.palette.neutral[700] }}
                  >
                    Energy Drink
                  </Typography>
                </div>
                <ChevronRightIcon
                  sx={{
                    color: theme.palette.neutral[400],
                    transform: "scaleY(1.1)",
                  }}
                />
              </div>
            </div>
            <div className={classes.tabItemContainer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="check icon"
                    className={classes.checkIcon}
                    src={WaterIcon}
                    style={{ marginRight: "0.5rem" }}
                  />

                  <Typography
                    variant="titleSmall"
                    className={classes.subtitle}
                    style={{ color: theme.palette.neutral[700] }}
                  >
                    Mineral Water
                  </Typography>
                </div>
                <ChevronRightIcon
                  sx={{
                    color: theme.palette.neutral[400],
                    transform: "scaleY(1.1)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          sx={{ marginTop: "0.875rem !important" }}
          variant="contained"
          className={classes.noneComplianceRecapButton}
          onClick={() => {
            removeImage(0);
            removeImage(1);
            navigate("/");
          }}
        >
          <Typography variant="bodySmall">{locale.Done}</Typography>
        </Button>
      </div>
    </ThemeProvider>
  );
};

export default Analysis;
