import { ThemeProvider,Typography } from "@mui/material";
import React from "react";
import theme from "../../theme";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const GoBackHeader = ({ location, text }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.header}>
        <div
          className={classes.cancelButton}
          onClick={() => {
            navigate(location);
          }}
        >
          <ChevronLeftIcon
            sx={{ transform: "scaleY(1.1)", transformOrigin: "center" }}
          />
        </div>
        <div className={classes.title}>
          <Typography variant="h3">{text}</Typography>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default GoBackHeader;
