import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import theme, {globalStyles} from "./../../theme"; // Import the theme
import { ThemeProvider } from "@mui/material/styles";
import globeIcon from "./../../Assets/Icons/Globe.png"; // Import the PNG file
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useStoreState, useStoreActions } from 'easy-peasy';

const Navbar = () => {
  const classes = globalStyles();

  // State for tracking the selected language
  const language = useStoreState((state) => state.locale);
  const setLanguage = useStoreActions((actions) => actions.setLocale);

  // Handle language change
  const handleChange = (event) => {
    const selectedLanguage = event.target.value;

    // Update state based on selected language (full name to short version)
    switch (selectedLanguage) {
      case "English":
        setLanguage("EN");
        break;
      case "Malaysian":
        setLanguage("MY");
        break;
      default:
        break;
    }


  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        className={classes.headerAppBar}
      >
        <Toolbar>
          {/* Logo/Title */}
          <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
            Planogram.AI
          </Typography>

          {/* Language Dropdown with Custom Globe Icon */}
          <Box
            className={classes.headerBox}
          >
            <div style={{ display: "flex", alignItems: "center",minWidth: "2.8rem" }}>
            {/* Custom Globe Icon */}
            <img
              src={globeIcon}
              alt="Globe Icon"
              style={{ width: "1rem", height: "1rem", marginRight: "-0.625rem" }}
            />
            {/* Display short language code in the navbar */}
            <Typography
              sx={{ marginLeft: 1.8, color: "white" }}
              variant="titleSmall"
            >
              {language}
            </Typography>
            </div>
            {/* Language Dropdown */}
            <Select
              value={""}
              onChange={handleChange}
              className={classes.headerLanguageSelect}
              IconComponent={(props) => (
                <KeyboardArrowDownIcon
                  sx={{ color: "white !important" }}
                  {...props}
                />
              )}
            >
              <MenuItem value="English">
                <Typography variant="bodySmall">English</Typography>
              </MenuItem>
              <MenuItem value="Malaysian">
                <Typography variant="bodySmall">Malaysian</Typography>
              </MenuItem>
            </Select>
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;
