import React, { useState, useRef } from "react";
import theme from "../../../theme";
import { ThemeProvider } from "@mui/styles";
import {
  Typography,
  // MenuItem,
  // Select,
  // InputLabel,
  // FormControl,
  //   Box,
  Button,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import { useStoreState, useStoreActions } from "easy-peasy";
import Modal from "@mui/material/Modal";
import Locale from "../../../locale";
import useStyles from "./cameraModalStyles";
import Webcam from "react-webcam";
import Frame from "../../../Assets/Icons/range.png";
import CaptureButton from "../../../Assets/Icons/Capture_button.png";
import CameraIcon from "../../../Assets/Icons/Camera.png";
import infoIcon from "./../../../Assets/Icons/Info.png";

const CameraModal = ({ open, onClose, modalTitle, picIndex }) => {
  const locale = Locale();
  const images = useStoreState((state) => state.images);
  const setImage = useStoreActions((actions) => actions.addImage);
  const removeImage = useStoreActions((actions) => actions.removeImage);

  const classes = useStyles();

  const webcamRef = useRef(null);
  const webcamRef2 = useRef(null);

  const [closeModal, setCloseModal] = useState(false);
  const openCloseModal = () => {
    setCloseModal(true);
  };
  const handleClose = () => {
    setCloseModal(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={closeModal}>
        <Fade in={closeModal}>
          <div
            className={classes.container}
            style={{
              outline: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: "21rem",
              backgroundColor: "white",
              borderRadius: "1rem",
              padding: "1rem",
              height: "auto",
            }}
          >
            <div className={classes.header} style={{ marginBottom: "1.2rem" }}>
              <div className={classes.title}>
                <Typography variant="h3" style={{ textAlign: "left" }}>
                  {locale.ImageCancelTitle}
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                variant="bodySmall"
                style={{ color: theme.palette.neutral[700],textAlign: "left" }}
              >
                {locale.ImageCancelText}
              </Typography>
            </div>
            <div className={classes.buttonContainer} style={{justifyContent:"flex-end", margin: "1rem 0 0"}}>
              <Button
                className={classes.leaveModalButton1}
                variant="outlined"
                onClick={() => {
                  handleClose();
                  onClose();
                }}
              >
                <Typography variant="titleSmall">{locale.Leave}</Typography>
              </Button>

              <Button
                className={classes.leaveModalButton2}
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                <Typography variant="titleSmall">
                  {locale.ContinueCapture}
                </Typography>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.container}>
            <div className={classes.header}>
              <div
                className={classes.cancelButton}
                onClick={() => {
                  openCloseModal();
                }}
              >
                <Typography variant="titleSmall">
                  {locale.ImageCancel}
                </Typography>
              </div>
              <div className={classes.title}>
                <Typography variant="h3">{modalTitle}</Typography>
              </div>
            </div>
            <div className={classes.descriptionContainer}>
              <Webcam
                style={{ display: "none" }}
                width={3024}
                height={4030}
                audio={false}
                ref={webcamRef2}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: "environment" }}
              />
              <div>
                <img
                  src={infoIcon}
                  alt="Info Icon"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginRight: "0.5rem",
                    filter: "invert(55%) sepia(7%) saturate(377%) hue-rotate(175deg) brightness(90%) contrast(90%)",
                  }}
                />
              </div>
              <Typography variant="bodySmall">
                {picIndex === 0
                  ? locale.ImageUploadStep1Text
                  : picIndex === 1
                  ? locale.ImageUploadStep2Text
                  : null}
              </Typography>
            </div>
            {!images[picIndex] || images[picIndex] == null ? (
              <center>
                <div style={{ width: "100%", position: "relative" }}>
                  <img
                    src={Frame}
                    alt="Frame"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "90%", // Adjust width to make it smaller (e.g., 90% of container width)
                      height: "90%", // Adjust height accordingly
                      transform: "translate(-50%, -50%)", // Center the frame within the container
                      zIndex: 1000,
                      pointerEvents: "none",
                    }}
                  />
                  <Webcam
                    forceScreenshotSourceSize={true}
                    width={430}
                    height={573}
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{ facingMode: "environment" }}
                  />
                </div>
                <img
                  src={CaptureButton}
                  alt="capture"
                  style={{
                    width: "3.75rem",
                    height: "3.75rem",
                    margin: "1.75rem 0",
                  }}
                  onClick={() => {
                    setImage({
                      index: picIndex,
                      value: webcamRef.current.getScreenshot({
                        width: 3024,
                        height: 4030,
                      }),
                    });
                  }}
                />
              </center>
            ) : (
              <>
                <img src={images[picIndex]} alt="LastPhoto" />
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.postCameraButtons}
                    variant="outlined"
                    onClick={() => {
                      removeImage(picIndex);
                    }}
                  >
                    <img
                      alt="Recapture"
                      src={CameraIcon}
                      style={{ marginRight: "0.75rem", width: "1.25rem" }}
                    />
                    <Typography variant="titleSmall">
                      {locale.Recapture}
                    </Typography>
                  </Button>

                  <Button
                    className={classes.postCameraButtons}
                    variant="contained"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <Typography variant="titleSmall">
                      {picIndex < 3 ? locale.SaveAndContinue : locale.Done}
                    </Typography>
                  </Button>
                </div>
              </>
            )}
          </div>
        </Fade>
      </Dialog>
    </ThemeProvider>
  );
};

export default CameraModal;
