import { useStoreState } from "easy-peasy";

const translations = {
    EN: {
        AllTimePerformance: "All-time performance",
        Card1Title: "Select a Planogram Recipe",
        Card2Title: "Capture entire fridge based on the ROI box",
        Card2Description: "Please ensure to have a clear view of the entire shelf or display area.",
        Card3Title: "POSM Materials Check",
        Card3Description: "Please select and capture the POSM materials. You can upload up to 3 images.",
        HelpText: "Need help with capture?",
        HelpButtonText: "Capture Guide",
        PriceTagInclude: "Include price tags",
        CaptureText: "Capture",
        AnalyzeButton: "Analyze",
        ImageText: "Image",
        HeaderTopBanner: "Please click on the camera button to capture display in different areas.",
        VerifiedText: "Verified",
        UnverfiedText: "Unverified",
        ChillerModalText: "Please select the chiller type that you are going to capture.",
        ChillerModalButton: "Continue",
        ChillerModalInput: "Chiller Type",
        ImageCancel: "Cancel",
        ImageUploadStep1Text: "Please open the chiller to capture the entire shelf or display area.",
        ImageUploadStep2Text: "Please close the chiller to capture the entire shelf or display area.",
        ImageUploadStep3Text: "Please capture all of the POSM inside and outside of the chiller.",
        Recapture: "Recapture",
        SaveAndContinue: "Save and Continue",
        Done: "Done",
        DiscardModelTitle: "Discard Content?",
        DiscardModelTitleText: "If you leave now, your photos will not be saved.",
        DiscardModalLeave: "Leave",
        DiscardModalContinue: "Continue capture",
        ImageCancelTitle:"Discard Content?",
        Leave: "Leave",
        ImageCancelText: "If you leave now, your photos will not be saved.",
        ContinueCapture: "Continue Capture",
        ChillerOpenText:"Chiller door open",
        ChillerCloseText:"Chiller door closed",
    },
    MY: {
        AllTimePerformance: "Prestasi Sepanjang Masa",
        Card1Title: "Pilih Resepi Planogram",
        Card2Title: "Tangkap keseluruhan peti sejuk berdasarkan kotak ROI",
        Card2Description: "Pastikan anda mempunyai pandangan jelas terhadap keseluruhan rak atau kawasan paparan.",
        Card3Title: "Semakan Bahan POSM",
        Card3Description: "Sila pilih dan tangkap bahan-bahan POSM. Anda boleh memuat naik sehingga 3 imej.",
        HelpText: "Perlu bantuan dengan tangkapan?",
        HelpButtonText: "Panduan Tangkapan",
        PriceTagInclude: "Sertakan tanda harga",
        CaptureText: "Tangkap",
        AnalyzeButton: "Analisis",
        ImageText: "Imej",
        HeaderTopBanner: "Sila klik butang kamera untuk menangkap paparan di kawasan yang berbeza.",
        VerifiedText: "Disahkan",
        UnverfiedText: "Tidak Disahkan",
        ChillerModalText: "Sila pilih jenis peti sejuk yang akan anda tangkap.",
        ChillerModalButton: "Teruskan",
        ChillerModalInput: "Jenis Peti Sejuk",
        ImageCancel: "Batal",
        ImageUploadStep1Text: "Sila buka peti sejuk untuk menangkap keseluruhan rak atau kawasan paparan.",
        ImageUploadStep2Text: "Sila tutup peti sejuk untuk menangkap keseluruhan rak atau kawasan paparan.",
        ImageUploadStep3Text: "Sila tangkap semua POSM di dalam dan di luar peti sejuk.",
        Recapture: "Tangkap Semula",
        SaveAndContinue: "Simpan dan Teruskan",
        Done: "Selesai",
        DiscardModelTitle: "Buang Kandungan?",
        DiscardModelTitleText: "Jika anda keluar sekarang, foto anda tidak akan disimpan.",
        DiscardModalLeave: "Keluar",
        DiscardModalContinue: "Teruskan tangkapan",
        ImageCancelTitle: "Buang Kandungan?",
        Leave: "Keluar",
        ImageCancelText: "Jika anda keluar sekarang, foto anda tidak akan disimpan.",
        ContinueCapture: "Teruskan Tangkapan",
        ChillerOpenText: "Pintu peti sejuk dibuka",
        ChillerCloseText: "Pintu peti sejuk ditutup",
    }
};

const Locale = () => {
    const language = useStoreState(state => state.locale);
    return translations[language] || translations.EN;
};

export default Locale;
