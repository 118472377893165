import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.neutral[50], // or  if using theme
    },
  },
  mainContainer:{
    margin:"0 1rem",
  },
  innerContainer:{
    padding: "1rem",
    backgroundColor: theme.palette.neutral[25],
    borderRadius: "1rem",
    margin:"1.25rem 0"
  },
  checkIcon:{
    width: "1.25rem",
    height: "1.25rem",
  },
  checkIconContainer:{
    margin: "0 0.5rem 0 0",
    padding: "0.375rem",
    backgroundColor: theme.palette.primary[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25%",
  },
  title: {
    margin: "1.25rem 0 !important",
  },
  subtitle:{
    color: theme.palette.primary[500],
  },
  tabStatusContainer:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabStatus0:{
    backgroundColor: theme.palette.error[50],
    padding: "0.125rem 0.5rem",
    color: theme.palette.error[300],
    borderRadius: "0.3rem",
  },
  tabStatus1:{
    backgroundColor: "#E3F7F6",
    padding: "0.125rem 0.5rem",
    color: "#058683",
    borderRadius: "0.3rem",
  },
  tabStatus2:{
    backgroundColor: theme.palette.success[50],
    padding: "0.125rem 0.5rem",
    color: theme.palette.success[300],
    borderRadius: "0.3rem",
  },
  tabItemContainer:{
    margin: "0.5rem 0 0 0",
    display:"flex",
    flexDirection:"column",
    padding:"0.5rem 0.75rem",
    backgroundColor:theme.palette.neutral[50],
    borderRadius: "0.6rem"
  },
  tabItem:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
    color: theme.palette.neutral[500],
    margin: "0.2rem 0"
  },
  tabSubItemContainer:{
    margin:"0.12rem 0",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    backgroundColor: theme.palette.neutral[200],
    padding:"0.5rem 0.75rem",
    borderRadius: "0.6rem",
  },
  recaptureButton:{
    padding: "0.5rem !important",
    width: "100% !important",
    textTransform: "none !important",
    borderRadius : "0.5rem !important",
    margin: "0.5rem 0 0 0 !important"
  },
  recaptureIcon:{
    filter: "brightness(0) invert(1)",
    width: "1.25rem",
    height: "1.25rem",
    marginLeft: "0.5rem",
  },
  noneComplianceContainer:{
    display: "flex",
    flexDirection: "column"
  },
  noneComplianceTitle:{
    marginLeft: "0.25rem !important",
    color: theme.palette.error[300],
  },
  noneComplianceIcon:{
    height: "1rem",
    width:"1rem"
  },
  noneComplianceRecapButton:{
    padding: "0.56rem !important",
    width: "100% !important",
    textTransform: "none !important",
    borderRadius : "0.5rem !important",
    margin: "0 !important",
    backgroundColor: `${theme.palette.primary[400]} !important`,
  },
  noneComplianceItems:{
    color: theme.palette.error[400],
    margin: "0.25rem 0 !important",
  }
}));

export default useStyles;
