import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1.2rem",
    backgroundColor: theme.palette.neutral[25],
    borderRadius: "0.75rem",
    border: `1px solid ${theme.palette.primary[200]}`,
    margin: "1rem 0",
  },
  
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },

  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "0.5rem",
  },

  circle: {
    backgroundColor: theme.palette.primary[100],
    padding: "0.7rem",
    borderRadius: "50px",
    marginBottom: "-1.25rem",
  },

  description: {
    textAlign: "left",
    color: theme.palette.neutral[500],
    margin: "1rem 0 0",
  },

  helpContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  helpRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  helpText: {
    color: theme.palette.neutral[500],
  },

  helpButtonText: {
    color: theme.palette.primary[400],
    marginRight: "0.5rem",
  },

  selectContainer: {
    width: "100%",
    margin: "1rem 0 0",
    textAlign: "left",
  },

  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "1.25rem 0 0 !important",
    width: "100%",
    border: `1px solid ${theme.palette.primary[300]}`,
    borderRadius: "0.5rem !important",
    textTransform: "none !important",
    color: `${theme.palette.neutral[25]} !important`,
    background: `${theme.palette.primary[400]} !important`,
  },

  buttonIcon: {
    filter: "brightness(0) invert(1)",
    width: "1.25rem",
    height: "1.25rem",
    margin: "0.5rem",
  },
  recaptureIcon: {
    width: "1.25rem",
    height: "1.25rem",
    margin: "0.5rem",
  },

  priceTagContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "1rem 0 0",
  },

  checkbox: {
    marginRight: "0.564rem",
  },

  postCameraButtons:{
    alignItems: "center",
    display: 'flex',
    flexDirection: 'row !important',
    width: "100% !important",
    textTransform: "none !important",
    margin: "1.25rem 0 0 !important",
},
}));

export default useStyles;
