import React from "react";
import useStyles from "./style";
import { Typography } from "@mui/material";

const ContainerTitle = ({ title, icon }) => {
    const classes = useStyles();
  return (
    <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "0.25rem"
            }}
          >
            <div className={classes.checkIconContainer}>
              <img
                alt={title}
                className={classes.checkIcon}
                src={icon}
              />
            </div>
            <Typography variant="titleMedium" className={classes.subtitle}>
              {title}
            </Typography>
          </div>
  );
};

export default ContainerTitle;