// RestAPI.jsx

const RestApi = () => {
  const getSkuImages = async () => {
    try {
      const requestBody = {
        labels: [1, 2, 3, 4, 5, 6, 7],
      };

      const response = await fetch("/backend/get_sku_labels/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const jsonResponse = await response.json();
      // console.log("Image SKU:", jsonResponse);

      // Find the maximum label to determine the array size
      const maxLabel = Math.max(
        ...jsonResponse.images.map((item) => item.label)
      );

      // Initialize an array with null values
      const imageStringArray = Array(maxLabel + 1).fill(null);

      // Populate the array based on the label
      jsonResponse.images.forEach((item) => {
        imageStringArray[item.label] = item.image;
      });

      return imageStringArray;
    } catch (error) {
      console.error("Error SKU :", error);
      return null;
    }
  };

  const getPOSMImages = async () => {
    try {
      const requestBody = {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      };

      const response = await fetch("/backend/get_posm_labels/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const jsonResponse = await response.json();
      // console.log("Image SKU:", jsonResponse);

      // Find the maximum label to determine the array size
      const maxLabel = Math.max(
        ...jsonResponse.images.map((item) => item.label)
      );

      // Initialize an array with null values
      const imageStringArray = Array(maxLabel + 1).fill(null);

      // Populate the array based on the label
      jsonResponse.images.forEach((item) => {
        imageStringArray[item.label] = item.image;
      });

      return imageStringArray;
    } catch (error) {
      console.error("Error SKU :", error);
      return null;
    }
  };
  // Function to upload a single file as JSON
  const uploadImage = async (url, sessionId, stock, base64Image) => {
    try {
      // Read the file as a base64 string
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      // Create JSON payload
      const payload = {
        sessionId: sessionId,
        stock: stock,
        image: base64Image, // base64-encoded image as a string
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const jsonResponse = await response.json();
      console.log("Image upload response:", jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.error("Error Image upload:", error);
      return null;
    }
  };

  // Function to upload multiple files
  const uploadMultipleFiles = async (url, files) => {
    try {
      const formData = new FormData();

      // Append each file to the FormData object
      Array.from(files).forEach((file, index) => {
        formData.append(`file_${index}`, file);
      });

      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const jsonResponse = await response.json();
      console.log("Multiple files upload response:", jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.error("Error in multiple files upload:", error);
      return null;
    }
  };

  // Function to get session ID using a GET request
  const getSessionId = async () => {
    try {
      const response = await fetch("/backend/session/", {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve session ID");
      }

      const jsonResponse = await response.json();
      console.log("Session ID:", jsonResponse);
      return jsonResponse.sessionId; // Assuming the response has a sessionId field
    } catch (error) {
      console.error("Error retrieving session ID:", error);
      return null;
    }
  };

  return {
    uploadImage,
    uploadMultipleFiles,
    getSessionId,
    getSkuImages,
    getPOSMImages,
  };
};

export default RestApi;
