import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./../../theme";
// import Locale from "./../../locale";
import Banner from "../../Components/Top_banner/Banner";
import MainPageTable from "../../Components/MainPageTable/MainPageTable";
import MainPageShop from "../../Components/MainPageShop/MainPageShop";

const MainPage = () => {

  return (
    <ThemeProvider theme={theme}>
      <Banner />
      <MainPageShop />
      <MainPageTable />
    </ThemeProvider>
  );
};

export default MainPage;
